
<div class="post-card">
    <div class="post-card-image">
        <img src="assets/img/blog/blog-1.jpg" alt="blog">
    </div>
    <div class="post-card-content">
        <div class="post-card-content-top">
            <div class="post-card-content-top-left">
                <div class="post-card-content-top-left-date">
                    <span>20</span>
                    <span>May</span>
                </div>
                <div class="post-card-content-top-left-category">
                    <span>Category</span>
                </div>
            </div>
            <div class="post-card-content-top-right">
                <div class="post-card-content-top-right-share">
                    <span>Share</span>
                    <ul>
                        <li>
                            <a href="#">
                                <i class="bx bxl-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="bx bxl-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="bx bxl-linkedin"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="bx bxl-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="post-card-content-bottom">
            <h3>
                
            </h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel
                facilisis. </p>
            <a href="blog-details.html" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
        </div>
    </div>
</div>

<!-- create styles for the post-card component -->
<!-- Path: src\app\components\post-card\post-card.component.scss -->


