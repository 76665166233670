<div id="software" class="software-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Software I Use For Work</h2>
            <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="software-img">
                    <img src="assets/img/personal-portfolio/3.png" alt="software image">
                    <div class="software-img-text">
                        <img src="assets/img/personal-portfolio/icon-1.png" alt="photoshop icon">
                        <p>Adobe Photoshop 2019</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="software-text">
                    <ngx-tabset>
                        <ngx-tab tabTitle="UI/UX Design">
                            <h2>Adobe Photoshop 2019</h2>
                            <p>Adobe Photoshop is a raster graphics editor developed and published by Adobe Inc. for Windows and macOS. It was originally created in 1988 by Thomas and John Knoll. Since then, the software has become the industry standard not only in raster graphics editing, but in digital art as a whole.</p>
                            <h3>Also Worked With</h3>
                            <ul>
                                <li><img src="assets/img/personal-portfolio/icon-2.png" alt="adobe xd icon"></li>
                                <li><img src="assets/img/personal-portfolio/icon-3.png" alt="figma icon"></li>
                            </ul>
                            <a routerLink="/portfolio-landing" class="software-link">Hire Now</a>
                        </ngx-tab>
                        <ngx-tab tabTitle="Web Development">
                            <h2>Visual Studio</h2>
                            <p>Visual Studio is a raster code editor developed and published by Adobe Inc. for Windows and macOS. It was originally created in 1988 by Thomas and John Knoll. Since then, the software has become the industry standard not only in raster graphics editing, but in digital art as a whole.</p>
                            <h3>Also Worked With</h3>
                            <ul>
                                <li><img src="assets/img/personal-portfolio/icon-2.png" alt="adobe xd icon"></li>
                                <li><img src="assets/img/personal-portfolio/icon-3.png" alt="figma icon"></li>
                            </ul>
                            <a routerLink="/portfolio-landing" class="software-link">Hire Now</a>
                        </ngx-tab>
                        <ngx-tab tabTitle="Video Editing">
                            <h2>Adobe Premierer</h2>
                            <p>Adobe Premiere is a raster video editor developed and published by Adobe Inc. for Windows and macOS. It was originally created in 1988 by Thomas and John Knoll. Since then, the software has become the industry standard not only in raster graphics editing, but in digital art as a whole.</p>
                            <h3>Also Worked With</h3>
                            <ul>
                                <li><img src="assets/img/personal-portfolio/icon-2.png" alt="adobe xd icon"></li>
                                <li><img src="assets/img/personal-portfolio/icon-3.png" alt="figma icon"></li>
                            </ul>
                            <a routerLink="/portfolio-landing" class="software-link">Hire Now</a>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
        </div>
    </div>
    <div class="software-shape">
        <img src="assets/img/shape/home-shape.png" alt="shape">
        <img src="assets/img/shape/home-shape-four.png" alt="shape">
        <img src="assets/img/shape/home-shape-two.png" alt="shape">
        <img src="assets/img/shape/subscribe-shape-three.png" alt="shape">
    </div>
</div>