import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agency-two-work-process',
  templateUrl: './agency-two-work-process.component.html',
  styleUrls: ['./agency-two-work-process.component.scss']
})
export class AgencyTwoWorkProcessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
