// create service to fetch posts from wordpress
// ng g s services/fetch-posts.
//
// Path: src\app\services\fetch-posts.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PostModel } from '../models/post-model';
import { firstValueFrom, Observable } from 'rxjs';



@Injectable(
  {
    providedIn: 'root'
  }
)
export class FetchPostsService {

  constructor(private http: HttpClient) { }


  async getAllPosts(): Promise<PostModel[]> {
    const posts = await firstValueFrom(this.http.get<PostModel[]>(environment.apiUrl));
    return posts;
  }

   getPostById(id: number): Observable<PostModel> {
    return this.http.get<PostModel>(`${environment.apiUrl}/${id}`);
  }

  getOnePostBySlug(slug: string): Observable<PostModel> {
    return this.http.get<PostModel>(`${environment.apiUrl}?slug=${slug}`);
  }







}


