import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class TitleService {
  getTitle(): any {
    return this.titleService.getTitle();
  }



  constructor(private titleService: Title) { }

 async setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  async setBackgroungImage(image: string) {
    const banner = document.getElementsByClassName('page-banner')[0];
    banner.setAttribute('style', `background:linear-gradient(1deg, rgb(6 159 233 / 92%), rgb(6 159 233)), url(${image})`);
  }

}