<div id="testimonials" class="testimonial-section">
    <div class="container-fluid">
        <div class="section-title">
            <span>Testimonial</span>
            <h2>People Quotes About Us</h2>
            <p>We value what our valued clients and users have to say about us. We are always ready to improve and be at your service.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="testimonial-img">
                    <img src="assets/img/app-landing/map.png" alt="map image">
                    <div class="client-img">
                        <img src="assets/img/app-landing/testimonial/1.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/2.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/3.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/4.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/5.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/6.png" alt="client image">
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="testimonial-slider">
                    <owl-carousel-o [options]="testimonialSlides">
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/testimonial/client-img.png" alt="client image">
                                <div class="client-info">
                                    <h3>John Doe</h3>
                                    <span>Traveler</span>
                                </div>
                                <p>I am enjoying this app so far. I was surprised that Shopify didn`t have a built-in function for something like this but glad your app fits the bill.</p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/testimonial/client-img-three.png" alt="client image">
                                <div class="client-info">
                                    <h3>Harry</h3>
                                    <span>SEO Expert</span>
                                </div>
                                <p>I am enjoying this app so far. I was surprised that Shopify didn`t have a built-in function for something like this but glad your app fits the bill.</p>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
        <div class="testimonial-shape">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
        </div>
    </div>
</div>