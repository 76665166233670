<div id="apps" class="keep-update pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="keep-updating-text">
                    <div class="section-title text-start">
                        <h2>Keep Business Updated on Phone</h2>
                    </div>
                    <p>ASTM International, formerly known as American Society for Testing and Materials, is an international standards organization that develops and publishes voluntary consensus technical standards for a wide range of materials, products, systems, and services.</p>
                    <div class="keep-btn">
                        <a routerLink="/sass-landing-two" class="active">
                            <i class="flaticon-playstore"></i>
                            <p>Get It on</p>
                            <h5>Google Play</h5>
                        </a>
                        <a routerLink="/sass-landing-two">
                            <i class="flaticon-app-store"></i>
                            <p>Available on the</p>
                            <h5>App Store</h5>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="keep-img">
                    <img src="assets/img/sass-landing/iphone-two.png" alt="iphone">
                </div>
            </div>
        </div>
    </div>
    <div class="keep-shape">
        <img src="assets/img/sass-landing/shapes/5.png" alt="shape">
    </div>
</div>