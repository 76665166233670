<div id="services" class="service-style-four pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Service</span>
            <h2>We are Providing Our Best Services</h2>
            <p>Landset is here to provide you with the best experience of creating a great venture. We help you to run your business with minimal tension.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-computer"></i>
                    <h3>Brand Identity</h3>
                    <p>Grow your identity in the big world of business with the help of our strategy and analysis.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-ui"></i>
                    <h3>UI/UX Design</h3>
                    <p>We provide a great user interface and user experience facility to improve the business website.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-web-programming"></i>
                    <h3>Development</h3>
                    <p>Offering incredible and highly functional business development that is entirely focused on clients.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-clipboard"></i>
                    <h3>Market Analysis</h3>
                    <p>We do the proper market analysis for you, we study your competitors and the state of your business.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-rocket"></i>
                    <h3>SEO Optimization</h3>
                    <p>With optimized SEO we ensure that you are ranked high in the search and reaching the max customer.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-pie-chart"></i>
                    <h3>Business Stategy</h3>
                    <p>When it comes after the noun, the ar djective usually doesn't get a hyphen. So we say an easy to.</p>
                </div>
            </div>
        </div>
    </div>
</div>