<app-page-banner [title]='pageTitle' [image]="pageImage"></app-page-banner>



<div class="contact-section ptb-100">
    <div class="section-title">
        <span>דברו איתנו</span>
        <h2>אנחנו זמינים גם בוואטסאפ</h2>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <p>אנחנו כאן כדי לעזור לכם בכל שאלה או בעיה שיש לכם.
                    <br>
                    אם יש לכם שאלה כלשהי על המוצרים או השירותים שלנו,
                    <br>
                    אנא השתמשו בטופס צור קשר להלן כדי ליצור איתנו קשר. נענה לכם בהקדם האפשרי.
                    <br>
                    אם הנכם רוצים לקבל מענה מיידי, אנא צרו קשר עם הצוות שלנו בטלפון:
                </p>

                <a href="tel:0527414444">052-741-4444</a>
                <p>או באימייל:</p>
                <a href="mailto:admin@interland.co.il">admin@interland.co.il</a>
            </div>
            <div class="col-lg-6">
                <div class="contact-form">
                    <div class="row">
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">


                                <form class="contact-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="שם" formControlName="fullName"
                                            required minlength="2">
                                        <span
                                            *ngIf="contactForm.get('fullName').hasError('required') && contactForm.get('fullName').touched">שדה חובה</span>
                                        <span
                                            *ngIf="contactForm.get('fullName').hasError('minlength') && contactForm.get('fullName').touched">לפחות 2 תווים</span>
                                    </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control" email="true" placeholder="אימייל"
                                            formControlName="email" required>
                                        <span
                                            *ngIf="contactForm.get('email').hasError('required') && contactForm.get('email').touched">שדה חובה</span>
                                        <span
                                            *ngIf="contactForm.get('email').hasError('email') && contactForm.get('email').touched">אימייל לא תקין</span>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="נושא"
                                            formControlName="subject" required>
                                        <span
                                            *ngIf="contactForm.get('subject').hasError('required') && contactForm.get('subject').touched">שדה חובה</span>
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control" placeholder="הודעה..." formControlName="message"
                                            required></textarea>
                                        <span
                                            *ngIf="contactForm.get('message').hasError('required') && contactForm.get('message').touched">שדה חובה</span>
                                    </div>
                                    <div class="standard-btn">
                                        <button type="submit" class="btn" [disabled]="!contactForm.valid">שליחה</button>
                                    </div>
                                </form>
                                <!-- <h1 *ngIf="successM">tal</h1> -->
                                <div *ngIf="successM" class="alert alert-success" role="alert">
                                    {{successMessage}}
                                </div>
                                <div *ngIf="errorM" class="alert alert-danger" role="alert">
                                    {{errorMessage}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
