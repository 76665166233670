<div class="page-banner">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1>{{title}}</h1>
          <!-- create dynamic breadcrumb -->
          <ul class="breadcrumbs">
            <li><a href="index.html">אינטרלנד</a></li>
            <li>{{title}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>