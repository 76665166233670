<app-page-banner [title]="pageTitle"></app-page-banner>



<!-- create a form for creating a new post using reactive forms -->
<form [formGroup]="postForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
        <label for="title">Title</label>
        <input type="text" class="form-control" id="postTitle" formControlName="postTitle">
    </div>
    <div class="form-group">
        <label for="imagePath">ImagePath</label>
        <input type="text" class="form-control" id="imagePath" formControlName="imagePath">
    </div>
    <div class="form-group">
        <label for="slug">Slug</label>
        <input type="text" class="form-control" id="slug" formControlName="slug">
    </div>
    <div class="form-group">
        <label for="content">Content</label>
        <br>
        <quill-editor class="content-editor" format="html" formControlName="content"
        [modules]="quillConfiguration"
        (onEditorCreated)="activateRTL($event)"
        style="height: 500px;"
        ></quill-editor>
 
    </div>

    <button type="submit" class="btn btn-primary">Submit</button>
</form>


<hr>
<!-- create a input for get slug and delete the post -->
<form [formGroup]="deleteForm" (ngSubmit)="onDelete(deleteForm.value.slugToDel)">
    <div class="form-group">
        <label for="slug">Slug</label>
        <input type="text" class="form-control" id="slugToDel" formControlName="slugToDel" placeholder="Enter the slug of the post you want to delete">
    </div>
    <button type="submit" class="btn btn-danger">Delete</button>





