import { Component, OnInit } from '@angular/core';
import { PostModel } from 'src/app/models/post-model';
import { PostsService } from 'src/app/services/posts.service';


@Component({
  selector: 'app-sass-one-apps',
  templateUrl: './sass-one-apps.component.html',
  styleUrls: ['./sass-one-apps.component.scss']
})
export class SassOneAppsComponent implements OnInit {

  posts: PostModel[];
  

  constructor(private postService: PostsService) { }

  async ngOnInit() {

    this.posts = await this.postService.getAllPosts();

  }

}
