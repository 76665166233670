<div class="newsletter">
    <div class="container">
        <div class="newsletter-area">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-text">
                        <h3>השאירו טלפון ונחזור בהקדם</h3>
                        <p>מעוניינים בבניית אתר, פיתוח ייחודי לאתר שלכם או שצצה לה פתאום תקלה מעצבנת? השאירו טלפון ונדבר
                            בקרוב...</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <form class="newsletter-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <input type="tel" class="form-control" placeholder="טלפון" formControlName="phoneNumber"
                            required autocomplete="off">
                        <button class="default-btn electronics-btn" type="submit">תחזרו אליי</button>
                    </form>
                    <div *ngIf="successM" class="alert alert-success" role="alert">
                        {{successMessage}}
                    </div>
                    <div *ngIf="errorM" class="alert alert-danger" role="alert">
                        {{errorMessage}}
                    </div>
                </div>
            </div>
            <div class="subscribe-shape">
                <img src="assets/img/shape/subscribe-shape.png" alt="shape">
                <img src="assets/img/shape/subscribe-shape-two.png" alt="shape">
                <img src="assets/img/shape/subscribe-shape-three.png" alt="shape">
            </div>
        </div>
    </div>
</div>