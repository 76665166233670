



<!-- create ngFor loop to display all blog posts with styling -->
<div class="post-card-container">
    <div *ngFor="let post of posts" class="post-card">
        <div >
            <div class="post-card-image">
                <img src='{{post.featured_image_link}}' alt="blog">
    
            </div>
            <div class="post-card-content">
                <div class="post-card-content-top">
                    <div class="post-card-content-top-left">
                        <div class="post-card-content-top-left-date">
                            <span>20</span>
                            <span>May</span>
                        </div>
                        <div class="post-card-content-top-left-category">
                            <span>Category</span>
                        </div>
                    </div>
                    <div class="post-card-title">
                        <h3 [innerHTML]="htmlDecode(post.title.rendered)"></h3>
                    </div>

                </div>
                <div class="post-card-content-bottom">
                    <h3>
                        
                    </h3>
                    <p [innerHTML]="post.excerpt.rendered"></p>
                    <a [href]="'blog/'+post.id" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>













