import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EmailService {
  
    constructor(private http: HttpClient) { }
  
    sendEmail(body) {
        let headers = new HttpHeaders({
          'Content-Type': 'application/json'
        })
        return this.http.post(`${environment.apiEmailUrl}`, body, {headers: headers});
  }
}
