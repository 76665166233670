<app-page-banner [title]="404"></app-page-banner>



<div class="not-found">

    <div class="container">

        <div class="row">

            <div class="col-lg-12">

                <div class="text-center">

                    <img class="not-found-img" src="assets/img/404.webp" alt="404" />

                    <p>אופס! הדף שאתה מחפש לא נמצא</p>
                    <div class="standard-btn">
                        <a routerLink="/">חזרה לדף הבית</a>
                    </div>

                </div>

            </div>

        </div>

    </div>
</div>