<div class="counter-style-two pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-6 col-md-3">
                <div class="counter-text">
                    <i class="flaticon-value"></i>
                    <h3><span class="counter">11</span>K</h3>
                    <p>Clients</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-6 col-md-3">
                <div class="counter-text">
                    <i class="flaticon-rating"></i>
                    <h3><span class="counter">950</span>+</h3>
                    <p>Happy Clients</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-6 col-md-3">
                <div class="counter-text">
                    <i class="flaticon-clipboard"></i>
                    <h3><span class="counter">5</span>+</h3>
                    <p>Pending Task</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-6 col-md-3">
                <div class="counter-text">
                    <i class="flaticon-rocket"></i>
                    <h3><span class="counter">1300</span>+</h3>
                    <p>Projects Done</p>
                </div>
            </div>
        </div>
    </div>
</div>