import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions, InMemoryScrollingOptions, RouterConfigOptions } from '@angular/router';
import { SassLandingOneComponent } from './components/pages/sass-landing-one/sass-landing-one.component';
import { AppLandingComponent } from './components/pages/app-landing/app-landing.component';
import { SassLandingTwoComponent } from './components/pages/sass-landing-two/sass-landing-two.component';
import { AgencyLandingOneComponent } from './components/pages/agency-landing-one/agency-landing-one.component';
import { AgencyLandingTwoComponent } from './components/pages/agency-landing-two/agency-landing-two.component';
import { PortfolioLandingComponent } from './components/pages/portfolio-landing/portfolio-landing.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { LoginComponent } from './components/pages/login/login.component';
import { BlogComponent } from './components/blog/blog.component';
import { SinglePostComponent } from './components/single-post/single-post.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NewPostComponent } from './components/new-post/new-post.component';

const routes: Routes = [
    {path: '', component: SassLandingOneComponent},
    // {path: '1', component: SassLandingTwoComponent},
    // {path: '2', component: AppLandingComponent},
     //{path: '3', component: AgencyLandingOneComponent},
    // {path: '4', component: AgencyLandingTwoComponent},
     //{path: '5', component: PortfolioLandingComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'login', component: LoginComponent},
    {path: 'q', component: NewPostComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog/:slug', component: SinglePostComponent},
    { path: '**', pathMatch: 'full', 
        component: NotFoundComponent },
];

const routerConfigOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
    useHash: false,
    scrollOffset: [0, 50],
};




@NgModule({
    imports: [RouterModule.forRoot(routes, routerConfigOptions) ],
    exports: [RouterModule]
})
export class AppRoutingModule { }