// fetch posts from wordpress using ferch-posts service and display them in blog-archive component
//
// Path: src\app\components\blog-archive\blog-archive.component.ts
import { Component, OnInit } from '@angular/core';
import { PostModel } from 'src/app/models/post-model';
import { FetchPostsService } from '../../services/fetch-posts.service';
import { HtmlEncodeService } from 'src/app/services/html-encode.service';

@Component({
  selector: 'app-blog-archive',
  templateUrl: './blog-archive.component.html',
  styleUrls: ['./blog-archive.component.scss']
})
export class BlogArchiveComponent implements OnInit {
  public posts: any;
  public images: any;
  
  htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  constructor(private fetchPostsService: FetchPostsService, private htmlEncodeService: HtmlEncodeService) { }
  



  
 

 async ngOnInit() {
    try{
      console.log('fetching posts');
      this.posts = await this.fetchPostsService.getAllPosts();
            }
    catch(error){
      console.log(error);
    }
  }
}











