// create component for sending email to server via nodemailer

import { Component, OnInit } from '@angular/core';
import { EmailService } from '../../../services/email.service';
import { ContactModel } from 'src/app/models/contact-model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TitleService } from 'src/app/services/title.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  pageTitle = 'צור קשר';
  pageImage = 'https://interland.co.il/logo-pink.png';

  contactForm: FormGroup;
  contact: ContactModel;
  successMessage: string;
  errorMessage: string;
  successM : boolean = false;
  errorM : boolean = false;
  NgxSmartModalService: any;
  constructor(private emailService: EmailService, private titleService: TitleService) { }

  ngOnInit() {
    this.titleService.setTitle(this.pageTitle);
    this.contactForm = new FormGroup({
      fullName: new FormControl(''),
      email: new FormControl(''),
      subject: new FormControl(''),
      message: new FormControl('')
    });
  }

  onSubmit() {
    // validate form
    if (!this.validateForm()) {
      return;
    }
    this.successMessage = 'ההודעה נשלחה בהצלחה';
    this.contact = this.contactForm.value;
    this.emailService.sendEmail(this.contact).subscribe(res => {
      this.successM = true;
      this.contactForm.reset();
    },
      err => {
        this.errorMessage = 'אירעה שגיאה בשליחת ההודעה';
        this.errorM = true;
    });
  }

  validateForm() {
    return this.contactForm.valid;
  }

}












