<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/logo.png" alt="logo">
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto">
                <li class="nav-item"><span class="nav-link" routerLink="/">בית</span></li>
                <li class="nav-item"><a [routerLink]="['/']" fragment="services"><span class="nav-link">שירותים</span></a></li>
                <li class="nav-item"><a [routerLink]="['/']" fragment="about"><span class="nav-link">אודות</span></a></li>
                <!-- <li class="nav-item"><a [routerLink]="['/']" fragment="pricing"><span class="nav-link">מחירים</span></a></li> -->
                <li class="nav-item"><span class="nav-link" routerLink="/blog">בלוג</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/contact">צור קשר</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/login">התחברות</span></li>
            </ul>
            <ul class="navbar-nav me-auto for-responsive">
                <li class="nav-item"><span class="nav-link" routerLink="/" (click)="toggleClass()">בית</span></li>
                <li class="nav-item"><a [routerLink]="['/']" fragment="services"><span class="nav-link" (click)="toggleClass()">שירותים</span></a></li>
                <li class="nav-item"><a [routerLink]="['/']" fragment="about"><span class="nav-link" (click)="toggleClass()">אודות</span></a></li>
                <!-- <li class="nav-item"><a [routerLink]="['/']" fragment="pricing"><span class="nav-link" (click)="toggleClass()">מחירים</span></a></li> -->
                <li class="nav-item"><span class="nav-link" routerLink="/blog" (click)="toggleClass()">בלוג</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/contact" (click)="toggleClass()">צור קשר</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/login" (click)="toggleClass()">התחברות</span></li>
            </ul>
            <div class="navbar-btn">
                <a  href="tel:0527414444" target="_blank">052-741-4444</a>
            </div>
        </div>
    </div>
</nav>