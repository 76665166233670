import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-sass-one-standard',
  templateUrl: './sass-one-standard.component.html',
  styleUrls: ['./sass-one-standard.component.scss']
})
export class SassOneStandardComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {



  }



}



