<div class="team-section ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Team Members</span>
            <h2>Our Creative Employee</h2>
            <p>Here is our dedicated team of Employee, who works creatively and relentlessly to bring you the best of the best service.</p>
        </div>
        <div class="team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="team-card">
                        <div class="team-img">
                            <img src="assets/img/creative-agency/team/1.jpg" alt="team member">
                            <div class="team-hover">
                                <div class="team-hover-text">
                                    <h3>Michle John</h3>
                                    <p>Market Analysis</p>
                                    <ul>
                                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    </ul>
                                </div> 
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="team-card">
                        <div class="team-img">
                            <img src="assets/img/creative-agency/team/2.jpg" alt="team member">
                            <div class="team-hover">
                                <div class="team-hover-text">
                                    <h3>George</h3>
                                    <p>Editor</p>
                                    <ul>
                                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    </ul>
                                </div> 
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="team-card">
                        <div class="team-img">
                            <img src="assets/img/creative-agency/team/3.jpg" alt="team member">
                            <div class="team-hover">
                                <div class="team-hover-text">
                                    <h3>Harry</h3>
                                    <p>Consultant</p>
                                    <ul>
                                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    </ul>
                                </div> 
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="team-card">
                        <div class="team-img">
                            <img src="assets/img/creative-agency/team/4.jpg" alt="team member">
                            <div class="team-hover">
                                <div class="team-hover-text">
                                    <h3>Noah</h3>
                                    <p>Researcher</p>
                                    <ul>
                                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    </ul>
                                </div> 
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="team-card">
                        <div class="team-img">
                            <img src="assets/img/creative-agency/team/1.jpg" alt="team member">
                            <div class="team-hover">
                                <div class="team-hover-text">
                                    <h3>Michle John</h3>
                                    <p>Market Analysis</p>
                                    <ul>
                                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                                    </ul>
                                </div> 
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>