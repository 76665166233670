<div id="apps" class="our-apps pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2">
                <div class="our-apps-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/sass-landing/vehidarta.webp" alt="iphone">
                </div>
            </div>
            <div class="col-lg-10">
                <div class="our-apps-text">
                    <div class="section-title text-center">
                        <span>מתוך הבלוג</span>
                        <h2>טיפים, מדריכים ועוד...</h2>
                    </div>
                    <div class="post-card-container">
                        <div *ngFor="let post of posts | slice:0:3" class="post-card">
                            <div>
                                <a [routerLink]='["blog/"+post.slug]'>
                                <div class="post-card-image">
                                    <img src='assets/img/blog/{{post.imagePath}}' alt="blog">
                        
                                </div>
                                <div class="post-card-content">
                                    <div class="post-card-content-top">
                                        <!-- <div class="post-card-content-top-left">
                                            <div class="post-card-content-top-left-date">
                                                <span>20</span>
                                                <span>May</span>
                                            </div>
                                            <div class="post-card-content-top-left-category">
                                                <span>Category</span>
                                            </div>
                                        </div> -->
                                        <div class="post-card-title">
                                            <h3>{{post.postTitle}}</h3>
                                        </div>
                    
                                    </div>
                                    <div class="post-card-content-bottom">
                                        <h3>
                                            
                                        </h3>
                                        <p class="post-excerpt" [innerHTML]="post.content | excerpt:100"></p>
                                        <a [routerLink]='["blog/"+post.slug]' class="read-more-btn">המשך קריאה <i class='bx bx-right-arrow-alt'></i></a>
                                    </div>
                                </div>
                            </a>
                            </div>
                        </div>
                    </div>
                    <div class="standard-btn text-center">
                        <a routerLink="/blog">מעבר לבלוג</a>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <div class="apps-shape">
        <img src="assets/img/shape/1.png" alt="shape">
        <img src="assets/img/shape/3.png" alt="shape">
        <img src="assets/img/shape/5.png" alt="shape">
        <img src="assets/img/shape/6.png" alt="shape">
        <img src="assets/img/shape/4.png" alt="shape">
    </div>
</div>

