<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <img src="assets/img/logo.png" alt="logo">
                    </div>
                    <p>המטרה שלנו להקל על חייכם ולתת לכם שירות מקיף תחת קורת גג אחת.</p>
                    <div class="footer-link">
                        <a href="tel:0527414444">
                            <i class="icofont-phone icofont-2x"></i>
                        </a>
                        <a href="https://wa.me/972527414444" target="_blank">
                            <i class="icofont-brand-whatsapp icofont-2x"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>אינטרלנד</h3>
                    <ul>
                        <li><a href="#"><i class="flaticon-left"></i> בניית אתרים </a></li>
                        <li><a href="#"><i class="flaticon-left"></i> בניית אתרי תדמית</a></li>
                        <li><a href="#"><i class="flaticon-left"></i> בניית אתרי מסחר </a></li>
                        <li><a href="#"><i class="flaticon-left"></i> שיעורים פרטיים והדרכות</a></li>
                        <li><a href="#"><i class="flaticon-left"></i> תחזוקת אתרים </a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>ניווט מהיר</h3>
                    <ul>
                        <li><a [routerLink]="['/']" fragment="home"><span><i class="flaticon-left"></i> בית </span></a>
                        </li>
                        <li><a [routerLink]="['/']" fragment="services"><span><i class="flaticon-left"></i> שירותים
                                </span></a></li>
                        <li><a [routerLink]="['/']" fragment="about"><span><i class="flaticon-left"></i> אודות
                                </span></a></li>
                        <li><a [routerLink]="['/']" fragment="pricing"><span><i class="flaticon-left"></i> מחירים
                                </span></a></li>
                        <li><span routerLink="/blog"><i class="flaticon-left"></i> בלוג </span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>יצירת קשר</h3>
                    <ul>
                        <li><a href="https://ul.waze.com/ul?place=ChIJsduGDoCHAhURYUHpsp_lYqs&ll=31.43422150%2C34.64241850&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location" target="_blank"><i class="flaticon-left"></i> כתובת - מושב תלמי בילו 240</a>
                        </li>
                        <li><a href="tel:0527414444" target="_blank"><i class="flaticon-left"></i> טלפון - 052-741-4444</a></li>
                        <li><a href="mailto:admin@interland.co.il" target="_blank"><i class="flaticon-left"></i> דוא"ל - admin@interland.co.il</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-4 lh-1 payments">
                    <img src="assets/img/sass-landing/footer/visa.png" alt="visa card">
                    <img src="assets/img/sass-landing/footer/paybox.png" alt="american-express">
                    <img src="assets/img/sass-landing/footer/bit.png" alt="discover">
                    <img src="assets/img/sass-landing/footer/mastercard.png" alt="master card">
                </div>
                <div class="col-lg-6 col-md-8 lh-1">
                    <p>© כל הזכויות שמורות <a href="https://interland.co.il/" target="_blank">לאינטרלנד</a></p>
                </div>
            </div>
        </div>
    </div>
    <div class="mobile-contact">
        <div class="mobile-contact-inner">

            <div class="mobile-call">
                <a href="https://wa.me/972527414444" target="_blank">
                    <i class="icofont-brand-whatsapp icofont-2x"></i>
                </a>
            </div>
            <div class="mobile-call">
                <a href="tel:0527414444" target="_blank">
                    <i class="icofont-phone icofont-2x"></i>
                </a>
            </div>
            <div class="mobile-call">
                <!-- <a href="mailto:admin@interland.co.il" target="_blank">
                    <i class="icofont-at icofont-2x"></i> 
                </a> -->
            </div>
        </div>



    </div>
    
</footer>