<app-page-banner [title]='pageTitle'></app-page-banner>


<div>
    <!-- contact us form -->
    <div class="contact-section ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="contact-img">
                        <img src="assets/img/sass-landing/login.png" alt="contact">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="contact-form">
                        <div class="section-title">
                            <span>אחסון אתרים</span>
                            <h2>התחברות אל חשבון האחסון שלך</h2>
                        </div>
                        <form>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <form action="https://hosting.interland.co.il/dologin.php?goto=clientarea.php"
                                            method="post" name="frmlogin" target="_blank">
                                            <label>אימייל</label><br>
                                            <input type="email" name="username" placeholder="Email"><br>
                                            <label>סיסמא</label><br>
                                            <input type="password" name="password" placeholder="סיסמא"><br><br>
                                            <div class="standard-btn">
                                                <button type="submit" class="btn">התחברות</button>
                                            </div>

                                        </form><br>
                                        <a href="https://hosting.interland.co.il/pwreset.php" target="_blank">שכחת את הסיסמא?
                                            לחץ כאן</a>




                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>