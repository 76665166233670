<app-page-banner [title]="pageTitle"></app-page-banner>


<div class="container">
    <!-- <main class="page"> -->
        <div class="section-title text-center">
            <br>
            <span>מעניין</span>
            <h2>טיפים, מדריכים ועוד...</h2>
            <!-- <p>We always provide our best qualities ever. Our best qualities are a clean user interface, subheading, easy to use.</p> -->
        </div>
<!-- create a blog archive component -->
<div class="post-card-container">
    <div *ngFor="let post of posts" class="post-card">
        <div>
            <a [routerLink]="[post.slug]">
                <div class="post-card-image">
                    <img src='assets/img/blog/{{post.imagePath}}' alt="blog">
                    
                </div>
            
            <div class="post-card-content">
                <div class="post-card-content-top">
                    <!-- <div class="post-card-content-top-left">
                        <div class="post-card-content-top-left-date">
                            <span>20</span>
                            <span>May</span>
                        </div>
                        <div class="post-card-content-top-left-category">
                            <span>Category</span>
                        </div>
                    </div> -->
                    <div class="post-card-title">
                        <h3>{{post.postTitle}}</h3>
                    </div>

                </div>
                <div class="post-card-content-bottom">
                    <h3>
                        
                    </h3>
                    <p [innerHTML]="post.content| excerpt:100"></p>
                    <a [routerLink]="[post.slug]" class="read-more-btn">המשך קריאה </a>
                </div>
            </div>
        </a>
        </div>
    </div>
</div>
<!-- </main> -->
</div>




