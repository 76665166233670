<div id="pricing" class="pricing-section price-section-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing Plan</span>
            <h2>A Smart Pricing Plan for You</h2>
            <p>We are here with a smart pricing plan for you. We usually provide you with a monthly plan and annual plan.</p>
        </div>
        <div class="pricing-tabs">
            <ngx-tabset>


                <ngx-tab tabTitle="בניית אתרים">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card">
                                <p>אתר תדמית</p>

                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i>עיצוב אישי </li>
                                        <li><i class="flaticon-checked"></i>בלוג </li>
                                        <li><i class="flaticon-checked"></i>בלוג </li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/contact">צור קשר</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card active-price business-class ">
                                <p>אתר סחר</p>

                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i>Limited Trips </li>
                                        <li><i class="flaticon-checked"></i>Limited Trips </li>
                                        <li><i class="flaticon-checked"></i>Limited Trips </li>
                                        <li> </li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/contact">צור קשר</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div class="price-card premium">
                                <p>אחר</p>
                                
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i>אתרי קורסים </li>
                                        <li><i class="flaticon-checked"></i>אתרי תוכן </li>
                                        <li><i class="flaticon-checked"></i>אתרי פורטפוליו </li>
                                        <li><i class="flaticon-checked"></i>ועוד</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/contact">צור קשר</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>

                <ngx-tab tabTitle="בנק שעות">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card">
                                <p>קטן</p>
                                <h3>150<span> /שעה</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> שעות תכנות - 1</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/">לרכישה</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card active-price business-class ">
                                <p>בינוני</p>
                                <h3>120<span> /שעה</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> שעות תכנות - 5</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/">לרכישה</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div class="price-card premium">
                                <p>גדול</p>
                                <h3>100<span> /שעה</span></h3>
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> שעות תענות - 10</li>
                                    </ul>
                                </div>
                                <div class="price-btn">
                                    <a routerLink="/">לרכישה</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
            
        </div>
    </div>
</div>