<div id="case-study" class="case-study-section ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <span>Case Studies</span>
            <h2>Some Great Works by Us</h2>
            <p>A comment is something that you say which expresses your opinion of something or which gives an explanation of it. If an event or situation.</p>
        </div>
        <div class="case-study-slider">
            <owl-carousel-o [options]="caseStudySlides">
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/1.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/logo-1.png" alt="logo">
                                    </div>
                                </div>                
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">Ride Share App</a></h3>
                            <p>We have created an app with incorporation with the ideas of our client. With the help of this app, you can go to places that are cheaper than using a cab.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/2.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/logo-2.png" alt="logo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">Rocket - New Marketing</a></h3>
                            <p>We helped a digital agency to grow its business with great strategy web designs and development. They help to grow your position in the market.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/3.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/logo-3.png" alt="logo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">Freelancing Marketing</a></h3>
                            <p>Freelance marketers are enabling firms and companies to sell their products efficiently. They help their clients to a create a website/business platform.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/1.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/logo-1.png" alt="logo">
                                    </div>
                                </div>                
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">Ride Share App</a></h3>
                            <p>We have created an app with incorporation with the ideas of our client. With the help of this app, you can go to places that are cheaper than using a cab.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/2.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/logo-2.png" alt="logo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">Rocket - New Marketing</a></h3>
                            <p>We helped a digital agency to grow its business with great strategy web designs and development. They help to grow your position in the market.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/3.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/logo-3.png" alt="logo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">Freelancing Marketing</a></h3>
                            <p>Freelance marketers are enabling firms and companies to sell their products efficiently. They help their clients to a create a website/business platform.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/1.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/logo-1.png" alt="logo">
                                    </div>
                                </div>                
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">Ride Share App</a></h3>
                            <p>We have created an app with incorporation with the ideas of our client. With the help of this app, you can go to places that are cheaper than using a cab.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="case-items">
                        <div class="case-img">
                            <img src="assets/img/creative-agency/case-study/2.png" alt="case study image">
                            <div class="project-logo">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <img src="assets/img/creative-agency/case-study/logo-2.png" alt="logo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="case-study-text">
                            <h3><a routerLink="/agency-landing-one">Rocket - New Marketing</a></h3>
                            <p>We helped a digital agency to grow its business with great strategy web designs and development. They help to grow your position in the market.</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>