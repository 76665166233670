<app-page-banner [title]='pageTitle'></app-page-banner>
<app-spinner></app-spinner>




<!-- <img src={{post.featured_image_link}} alt="image">
<div>{{post.id}}</div>
<div>{{post.postTitle}}</div> -->

<div class="container">
  <div class="row">
    <div class="col-md-8">
      <div class="post">
        <div class="post-image">
          <img src='assets/img/blog/{{post.imagePath}}' alt="image">
        </div>
        <div class="post-title">
          <h2>{{post.postTitle}}</h2>
        </div>
        <div class="post-content">
          <div [innerHTML]="post.content"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="sidebar">

        <div class="sidebar-item">
          <h2>עוד פוסטים...</h2>
          <div class="latest-posts-container">
            <div class="latest-posts" *ngFor="let post of posts | slice: 0:4">
              <a *ngIf="post.postTitle !== pageTitle" href="/blog/{{post.slug}}">
                <img src='assets/img/blog/{{post.imagePath}}' alt="image">
                <h5>{{post.postTitle}}</h5>
              </a>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>




