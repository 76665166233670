<app-sass-two-banner></app-sass-two-banner>

<app-sass-two-services></app-sass-two-services>

<app-sass-two-features></app-sass-two-features>

<app-sass-two-apps></app-sass-two-apps>

<app-sass-two-pricing></app-sass-two-pricing>

<app-sass-two-funfacts></app-sass-two-funfacts>

<app-sass-two-testimonial></app-sass-two-testimonial>