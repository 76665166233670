<div id="about" class="features-style-two pb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="feature-image">
                    <img src="assets/img/sass-landing/laptop.png" alt="laptop">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="feature-text">
                    <div class="section-title text-start">
                        <h2>1000 of Customers Most Favourite Software</h2>
                        <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-checked"></i> Clean User Interface</li>
                        <li><i class="flaticon-checked"></i> Easy To Use</li>
                        <li><i class="flaticon-checked"></i> Best Performence</li>
                        <li><i class="flaticon-checked"></i> 24/7 Support</li>
                        <li><i class="flaticon-checked"></i> Free New Updates</li>
                        <li><i class="flaticon-checked"></i> Strong Secured Data Storage</li>
                    </ul>
                    <div class="feature-btn">
                        <a routerLink="/sass-landing-two">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="featues-shape">
        <img src="assets/img/sass-landing/shapes/4.png" alt="shape">
    </div>
</div>