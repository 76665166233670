<div id="about" class="why-choose why-choose-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span>למה דווקא אנחנו?</span>
            <h2>מקצועיות עם תמיכה איכותית ושירות מעולה</h2>
            <!-- <p>We always provide our best qualities ever. Our best qualities are a clean user interface, subheading, easy to use.</p> -->
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/sass-landing/shira.png" alt="why choose image">
                </div>
            </div>
            <div class="col-lg-5">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="1s">
                    <div class="media d-flex">
                        <i class="flaticon-computer mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">תמיכה ושירות לאחר הבנייה</h3>
                            <p>אנחנו נעזור לכם לנהל את האתר שלכם ונפתור כל בעיה שעלולה לצוץ. אנו זמינים בכל זמן לעזור ולתמוך בכם כדי להבטיח שהאתר שלכם יפעל במצב הטוב ביותר. </p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-layers mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">בנייה בסטנדרט גבוה</h3>
                            <p>אנו משתמשים בטכנולוגיות וכלים מתקדמים כדי לספק לכם אתר מאובזר ומתאים לצרכים שלכם. האתר שלכם יעזור לכם להפעיל את העסק שלכם בצורה הטובה ביותר ובאופן הכי נוח עבור הלקוחות שלכם.</p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-countdown mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">עמידה בזמנים</h3>
                            <p>אנו מאפשרים לכם לעקוב אחר התהליך ולקבל עדכונים רצופים כדי להבטיח שהאתר שלכם יהיה מוכן בזמן המתאים עבורכם.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shapes">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
            <img src="assets/img/shape/6.png" alt="shape">
            <img src="assets/img/shape/7.png" alt="shape">
        </div>
    </div>
</div>
