// create component for single post

import { Component, OnInit } from '@angular/core';
import { PostModel } from '../../models/post-model';
import { PostsService } from '../../services/posts.service';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-single-post',
  templateUrl: './single-post.component.html',
  styleUrls: ['./single-post.component.scss']
})
export class SinglePostComponent implements OnInit {

  post: PostModel;
  pageTitle: string;

  posts: PostModel[];
  

  constructor(private postsService: PostsService, private route: ActivatedRoute, private metaService: Meta) {
      
  
   }

  async ngOnInit() {


    const slug = this.route.snapshot.paramMap.get('slug');

    // get post from service getOnePost
    this.post = await this.postsService.getOnePost(slug);
    
    this.pageTitle = await this.post.postTitle;


    // get post from service
    this.posts = await this.postsService.getAllPosts();

    this.metaService.updateTag({ name: 'description', content: this.post.content });
    this.metaService.updateTag({ name: 'keywords', content: this.post.postTitle });
    this.metaService.updateTag({ name: 'robots', content: 'index, follow' });
    this.metaService.updateTag({ name: 'googlebot', content: 'index, follow' });
    this.metaService.updateTag({ name: 'author', content: 'טל נסימי' });
    this.metaService.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
    this.metaService.updateTag({ name: 'og:title', content: this.post.postTitle });
    this.metaService.updateTag({ name: 'og:description', content: this.post.content });
    this.metaService.updateTag({ name: 'og:image', content: 'https://interland.co.il/assets/img/blog/' + this.post.imagePath });
  
  }



}


