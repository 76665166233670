
export class PostModel {
    
    postTitle: string;
    content: string;
    imagePath: string;
    slug: string;
}



