import { Component, OnInit } from '@angular/core';
import { PostModel } from '../../models/post-model';
import { PostsService } from '../../services/posts.service';



@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  pageTitle = 'בלוג';
  pageSubtitle = 'כל מה שקשור לפיתוח ולעיצוב אתרים';
  

  posts: PostModel[];

  constructor(private postsService: PostsService) { }

 async ngOnInit() {
    this.posts = await this.postsService.getAllPosts();
    


  }

  }


