<div id="testimonials" class="testimonial-style-four">
    <div class="container">
        <div class="section-title">
            <span>Testimonial</span>
            <h2>People Quotes About Us</h2>
            <p>A comment is something that you say which expresses your opinion of something or which gives an explanation of it. If an event or situation.</p>
        </div>
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="testimonial-slider-wrapper">
                    <owl-carousel-o [options]="testimonialWrapperSlides">
                        <ng-template carouselSlide>
                            <div class="tsetimonial-card">
                                <img src="assets/img/creative-agency/testimonial/1.png" alt="client image">
                                <h3>John Doe</h3>
                                <span>Treaveller</span>
                                <p>I am really enjoying this app so far. I was surprised that Shopify didn`t have a built in function for something like this, but glad your app fits the bill.</p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="tsetimonial-card">
                                <img src="assets/img/creative-agency/testimonial/2.png" alt="client image">
                                <h3>Jack</h3>
                                <span>Consultant</span>
                                <p>I am really enjoying this app so far. I was surprised that Shopify didn`t have a built in function for something like this, but glad your app fits the bill.</p>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>