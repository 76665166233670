<div id="testimonials" class="testimonial-section pb-100">
    <div class="container-fluid">
        <div class="section-title">
            <span>המלצות</span>
            <h2>מה הלקוחות אומרים עלינו</h2>
            <p>אנו מעריכים את מה שיש ללקוחות המוערכים שלנו לומר עלינו ותמיד מוכנים להשתפר ולעמוד לשירותכם.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="testimonial-img">
                    <img src="assets/img/app-landing/map.png" alt="map image">
                    <div class="client-img">
                        <i class="icofont-boy icofont-2x"></i>
                        <i class="icofont-business-man icofont-1x"></i>
                        <i class="icofont-girl-alt icofont-1x"></i>
                        <i class="icofont-girl icofont-2x"></i>
                        <i class="icofont-man-in-glasses icofont-1x"></i>
                        <i class="icofont-waiter icofont-1x"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="testimonial-slider">
                    <owl-carousel-o [options]="testimonialSlides">
                        <ng-template carouselSlide class="item-t">
                            <div class="testimonial-item">
                                <div class="client-info purple-test">
                                    <i class="icofont-girl icofont-3x"></i>
                                    <h3>מאי ערד</h3>
                                    <span>May Digital</span>
                                </div>
                                <p>כבר המון זמן שחיפשתי מתכנת לעבודה בשיתוף פעולה, עברתי כמה וכמה מתכנתים עד שהגעתי לטל.
                                    <br>
                                    נעים לעבוד איתו, סופר מקצועי, נכנס מהר לראש שלי ופותר תקלות במהירות, זמין בכל שעה!
                                    <br>
                                    הייתי ממליצה לכם אבל אז לא יהיה לו זמן אליי אז הוא גרוע ומעצבן
                                </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide class="item-t">
                            <div class="testimonial-item">
                                <div class="client-info red-test">
                                    <i class="icofont-man-in-glasses icofont-3x"></i>
                                    <h3>אבי עמר</h3>
                                    <span>חבורה - חדשות מאנשים</span>
                                </div>
                                <p>
                                    אני ממליץ בחום על טל היקר, הבעלים של חברת פיתוח האתרים "אינטרלנד". טל הוא מקצוען בתחום הפיתוח והעיצוב של אתרי אינטרנט ומציע שירותים מקיפים ומקצועיים ללקוחותיו.
                                    <br>
                                    טל מתגייס במלוא כוחו כדי להבין את הצרכים של הלקוח ולעזור לו להגיע למטרותיו באמצעות האתר. הוא תמיד זמין לשאלות ולדיונים נוספים ומספק תמיכה מקצועית לאורך כל התהליך.
                                    <br>
טל הוא בעל יכולות מקצועיות גבוהות ואדם מעורב ויעיל, לויאלי ואחראי ואני ממליץ בחום לקחת את שירותיו לפיתוח והקמת אתרי אינטרנט מקצועיים
                                </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <div class="client-info yellow-test">
                                    <i class="icofont-woman-in-glasses icofont-3x"></i>
                                    <h3>הודיה אדר</h3>
                                    <span>OD - Studio</span>
                                </div>
                                <p>טל נסימי כשמו כן הוא - נס לעולם האתרים
                                    <br>
                                    טל הוא מתכנת ובונה אתרים מנוסה ואיכותי אבל יותר מהכל - בן אדם!  אמין, שירותי, נעים ואנושי.
                                    <br>
                                    ורסטילי ביכולותיו וזמין לכל שאלה. ממליצה בחום לעבוד איתו❤
                                </p>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
        <div class="testimonial-shape">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
        </div>
    </div>
</div>