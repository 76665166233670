<div class="counter-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-9 offset-lg-1">
                <div class="row">
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-value"></i>
                            <h2><span class="counter">300</span>+</h2>
                            <p>לקוחות</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-rating"></i>
                            <h2><span class="counter">100</span>%</h2>
                            <p>שביעות רצון</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-clipboard"></i>
                            <h2><span class="counter">5</span>+</h2>
                            <p>משימות ממתינות</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-rocket"></i>
                            <h2><span class="counter">1300</span>+</h2>
                            <p>פרויקטים בוצעו</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="counter-img wow" data-wow-duration="1s">
        <img src="assets/img/sass-landing/habura.webp" alt="laptop">
        <img src="assets/img/sass-landing/habura-mobile.webp" alt="laptop">
    </div>
</div>