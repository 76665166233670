import { Component, NgModule, OnInit, ViewChild } from '@angular/core'
import { PostModel } from '../../models/post-model';
import { PostsService } from '../../services/posts.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'







@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.scss']
})


export class NewPostComponent implements OnInit {
  errorMessage: string;
  deleteMessage: string;
  postForm: any;
  deleteForm: any;



  quillConfiguration = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
    
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
    
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
    
      ['clean'] 

    ],
  }
slugToDel: string;




  constructor(private postsService: PostsService, private router: Router, fb: FormBuilder) { 
    this.post = new PostModel();
    
    this.postForm = new FormGroup({
      postTitle: new FormControl(''),
      content: new FormControl(''),
      imagePath: new FormControl(''),
      slug: new FormControl('')
    });

    this.deleteForm = new FormGroup({
      slugToDel: new FormControl('') 
    });






  }




  
  pageTitle = 'פוסט חדש';
  pageSubtitle = 'כתוב פוסט חדש';
  pageImage = 'https://interland.co.il/logo-pink.png';
  slug: string;

  post: PostModel;


    ngOnInit(): void {


    }


  // create a function that will send the post to service postService Promise
  async onSubmit() {

    this.post = this.postForm.value;
    await this.postsService.addPost(this.post).then(res => {
      // this.router.navigate(['/posts']);
    },
      err => {
        this.errorMessage = 'אירעה שגיאה בשליחת ההודעה';
    });
  }

onDelete(slugToDel: string) {
  console.log(slugToDel);
     this.postsService.deletePost(slugToDel).then(res => {
      console.log(slugToDel);
      // this.router.navigate(['/posts']);
      console.log('deleted');
    },
      err => {
        this.deleteMessage = 'אירעה שגיאה בשליחת ההודעה';
        console.log('error');
    });
  }

  activateRTL(editor) {
    editor.format('align', 'right')
    editor.format('direction', 'rtl')
  }








}

