<div id="services" class="service-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>השירותים שלנו</span>
            <h2>שירותי בניית אתרים מקצועיים ואיכותיים</h2>
            <p>אנו מזמינים אותך לסייר בין מגוון הפתרונות הדיגיטליים שאנו מציעים, כדי שגם אתה תוכל לנצל את הבמה האינטרנטית – להצלחת העסק שלך!</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 ">
                <div class="service-card service-card-one wow fadeInUp" data-wow-duration="1s">
                    <i class="flaticon-web-programming"></i>
                    <h3>פיתוח ובניית אתרים</h3>
                    <p>פיתוח אתרים עם ניסיון של למעלה מ-15 שנה. 
                        אתרי האינטרנט שלנו מקודדים לשלמות מאפס בסטודיו שלנו.
                        אנו יוצרים אתרי אינטרנט גמישים ומורכבים המנוהלים בקלות כך שיהיה קל לעדכן אותם בעתיד.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-two wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                    <i class="flaticon-rocket"></i>
                    <h3>קידום אורגני</h3>
                    <p>הרוב המוחלט מהגולשים מעדיפים לבחור בתוצאות החיפוש האורגניות ולא מגיע לעמוד השני או השלישי של תוצאות החיפוש!

                        לכן האתר שלך חייב להופיע בעמודים הראשונים בכל מילות המפתח הרלוונטיים לעסק שלך.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-three wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                    <i class="flaticon-folder"></i>
                    <h3>ניהול אתרים</h3>
                    <p>תחזוקה ועדכון תכנים לאתר הם חשובים לאתר ונועדו לשמור על האתר עדכני ובטוח.
                         זה כולל את העדכונים הנדרשים לתוכנה כדי למנוע באגים ולאפשר לאתר לעבוד כהלכה.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-four wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                    <i class="flaticon-relationship"></i>
                    <h3>שיעורים פרטיים</h3>
                    <p>הדרכות אחד על אחד בבניית אתרים על בסיס מערכת וורדפרס או אתר מבוסס קוד.
                        ללמוד את הכלים והטכנולוגיות הנדרשות כדי לבנות ולנהל אתר אינטרנט מקצועי ואיכותי.

                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-five wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                    <i class="flaticon-cloud-computing"></i>
                    <h3>אחסון אתרים</h3>
                    <p>שירות אחסון מנוהל וביצועים גבוהים מאוד הופכים את אינטרלנד לחברת אחסון אתרים המשתלמת ביותר</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-card service-card-six wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s">
                    <i class="flaticon-clipboard"></i>
                    <h3>פתרון תקלות</h3>
                    <p>איתור ופתרון באגים ובעיות טכניות שעלולות לגרום לאתר לעבוד באופן לא תקין.</p>
                </div>
            </div>
            <!-- <div class="standard-btn">
                <a routerLink="/">לכל השירותים</a>
            </div> -->
        </div>
    </div>
</div>