<div class="trusted-company">
    <div class="container">
        <div class="section-title text-center">
            <span>בין לקוחותינו</span>
            <h2>350+ לקוחות שסומכים עלינו&nbsp; </h2>
        </div>
        <div class="company-slider">
            <owl-carousel-o [options]="companySlides">
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/partners/may_logo.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/partners/infomed.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/partners/od-logo.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/partners/shira.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/partners/webtailors.png" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/partners/flip.svg" alt="company logo">
                    </a>
                </ng-template>
                <ng-template carouselSlide>
                    <a href="#" target="_blank">
                        <img src="assets/img/partners/diamond.png" alt="company logo">
                    </a>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>