<div id="home" class="main-banner banner-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-8">
                        <div class="banner-text">
                            <h1>Super Fast & Reliable Booking App</h1>
                            <p>Traveling? Don’t have the time to purchase tickets on the spot? Book your tickets within a moment with the help of Euzan.</p>
                            <div class="banner-btn">
                                <a routerLink="/app-landing">Download App</a>
                                <button class="banner-video" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="flaticon-play-button"></i> Watch Video</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="banner-img text-end">
                            <img src="assets/img/app-landing/1.png" alt="iphone">
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
    <div class="banner-shape">
        <img src="assets/img/shape/home-shape.png" alt="shape">
        <img src="assets/img/shape/home-shape-two.png" alt="shape">
        <img src="assets/img/shape/home-shape-four.png" alt="shape">
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>