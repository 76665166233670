import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../services/title.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    
pageTitle: string = 'כניסה למערכת';

  constructor(private TitleService: TitleService) { }

  ngOnInit(): void {
    this.TitleService.setTitle(this.pageTitle);
  }

}
