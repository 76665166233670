<div id="pricing" class="pricing-section pricing-style-two">
    <div class="container">
        <div class="section-title">
            <h2>Choose Your Affortable Price Plan</h2>
            <p>A comment is something that you say which expresses your opinion of something or which gives an explanation of it. If an event or situation.</p>
        </div>
        <div class="pricing-tabs">
            <ngx-tabset>
                <ngx-tab tabTitle="Monthly Plan">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card">
                                <div class="price">
                                    <h3>$30 <span>/Month</span></h3>
                                    <p>Basic Account</p>
                                </div>
    
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-block"></i> No Free Trip</li>
                                        <li><i class="flaticon-block"></i> Membership Card</li>
                                        <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                        <li><i class="flaticon-block"></i> Money Return</li>
                                    </ul>
                                </div>
    
                                <div class="price-btn">
                                    <a routerLink="/sass-landing-two">Get Started</a>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card business-class active-price">
                                <div class="price">
                                    <h3>$50 <span>/Month</span></h3>
                                    <p>Business Class</p>
                                </div>
    
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                        <li><i class="flaticon-block"></i> Money Return</li>
                                    </ul>
                                </div>
    
                                <div class="price-btn">
                                    <a routerLink="/sass-landing-two">Get Started</a>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div class="price-card premium">
                                <div class="price">
                                    <h3>$100 <span>/Month</span></h3>
                                    <p>Permium</p>
                                </div>
    
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-checked"></i> Trip Cancellation</li>
                                        <li><i class="flaticon-checked"></i> Money Return</li>
                                    </ul>
                                </div>
    
                                <div class="price-btn">
                                    <a routerLink="/sass-landing-two">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Annual Plan">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card">
                                <div class="price">
                                    <h3>$35 <span>/Annual</span></h3>
                                    <p>Basic Account</p>
                                </div>
    
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-block"></i> No Free Trip</li>
                                        <li><i class="flaticon-block"></i> Membership Card</li>
                                        <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                        <li><i class="flaticon-block"></i> Money Return</li>
                                    </ul>
                                </div>
    
                                <div class="price-btn">
                                    <a routerLink="/sass-landing-two">Get Started</a>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="price-card business-class active-price">
                                <div class="price">
                                    <h3>$99 <span>/Annual</span></h3>
                                    <p>Business Class</p>
                                </div>
    
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-block"></i> Trip Cancellation</li>
                                        <li><i class="flaticon-block"></i> Money Return</li>
                                    </ul>
                                </div>
    
                                <div class="price-btn">
                                    <a routerLink="/sass-landing-two">Get Started</a>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div class="price-card premium">
                                <div class="price">
                                    <h3>$150 <span>/Annual</span></h3>
                                    <p>Permium</p>
                                </div>
    
                                <div class="price-feature">
                                    <ul>
                                        <li><i class="flaticon-checked"></i> Limited Trips</li>
                                        <li><i class="flaticon-checked"></i> Available Trip History</li>
                                        <li><i class="flaticon-checked"></i> Only 1 offer/month</li>
                                        <li><i class="flaticon-checked"></i> Special Discount</li>
                                        <li><i class="flaticon-checked"></i> No Free Trip</li>
                                        <li><i class="flaticon-checked"></i> Membership Card</li>
                                        <li><i class="flaticon-checked"></i> Trip Cancellation</li>
                                        <li><i class="flaticon-checked"></i> Money Return</li>
                                    </ul>
                                </div>
    
                                <div class="price-btn">
                                    <a routerLink="/sass-landing-two">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
    <div class="price-shape">
        <img src="assets/img/sass-landing/shapes/8.png" alt="shap">
        <img src="assets/img/sass-landing/shapes/9.png" alt="shap">
        <img src="assets/img/sass-landing/shapes/10.png" alt="shap">
        <img src="assets/img/sass-landing/shapes/9.png" alt="shap">
    </div>
</div>