<app-navbar></app-navbar>
<router-outlet></router-outlet>
<app-newsletter></app-newsletter>
<app-footer></app-footer>
<app-spinner></app-spinner>
<button
  ngxScrollTop
  [ngxScrollTopMode]="'smart'"
  class="scroll-top"
  color="primary"
  mat-mini-fab
>
↑
</button>

<!-- add whatsapp button using ngx-whatsapp -->





