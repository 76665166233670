import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(private metaService: Meta) { 

  }

  ngOnInit() {
    this.metaService.updateTag({ name: 'description', content: 'עמוד לא נמצא' });
    this.metaService.updateTag({ name: 'keywords', content: 'עמוד לא נמצא' });
    this.metaService.updateTag({ name: 'robots', content: 'noindex' });
    this.metaService.updateTag({ name: 'googlebot', content: 'noindex' });
    this.metaService.updateTag({ name: 'author', content: 'טל נסימי' });
    this.metaService.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });

}

}






