import { Component, OnInit, Input } from '@angular/core';
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'app-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss']
})
export class PageBannerComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() image: string;

  constructor(private titleService: TitleService) { }

  ngOnInit(): void {
    try {
      this.titleService.setTitle(this.title);
      this.titleService.setBackgroungImage(this.image);
    }
    catch (error) {
      console.log(error);
    }
  }

}
