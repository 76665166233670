<div id="about" class="why-choose pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/app-landing/2.png" alt="why choose image">
                </div>
            </div>
            <div class="col-lg-7 offset-lg-1">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">
                    <div class="section-title">
                        <span>Why Choose Us?</span>
                        <h2>We are Providing the Best facilities!</h2>
                        <p>Euzan is here to provide you with the best experience of booking tickets online. With this app, you do not have to worry about traveling miles and buying tickets way ahead of time. We provide a trustworthy solution to begin your journey.</p>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-computer mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Clean User Interface</h3>
                            The app is easy to handle and understand. You will not have to wonder much and just jump to booking your destination ticket.
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-cinema mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Comfort Booking</h3>
                            Don’t worry just book your tickets ahead of time or instantly while sipping a coffee or while taking a walking. The app is easily manageable.
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-countdown mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Time-Saving</h3>
                            We know how hectic life can be. Book the ticket for your target destination with Euzan within a second. Take the extra time to pack properly.
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-wallet mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Online Payment</h3>
                            We accept online payment. Manage your trip without the hassle of buying the ticket in person or paying for it in cash. Just transfer the money online.
                        </div>
                    </div>
                    <div class="why-choose-btn">
                        <a routerLink="/app-landing" class="active">
                            <i class="flaticon-playstore"></i>
                            <p>Get It on</p>
                            <h5>Google Play</h5>
                        </a>
                        <a routerLink="/app-landing">
                            <i class="flaticon-app-store"></i>
                            <p>Available on the</p>
                            <h5>App Store</h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>