import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PostModel } from '../models/post-model';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(private http: HttpClient) { }

  // getAllPosts(): Observable<PostModel[]> {
  //   return this.http.get<PostModel[]>(`${environment.apiUrl}/posts`);
  // }

// get one post by id

// get all posts and save them in session storage
// async getAllPosts(): Promise<PostModel[]> {
//   // check if posts are in session storage
//   if (sessionStorage.getItem('posts')) {
//     return JSON.parse(sessionStorage.getItem('posts'));
//   }
//   // if not, get them from the server and save them in session storage
//   else {
//     const posts = await firstValueFrom(this.http.get<PostModel[]>(`${environment.apiUrl}/posts`));
//     sessionStorage.setItem('posts', JSON.stringify(posts));
//     return posts;
//   }

// }

// get all posts and set header
async getAllPosts(): Promise<PostModel[]> {
  // check if posts are in session storage
  if (sessionStorage.getItem('posts')) {
    return JSON.parse(sessionStorage.getItem('posts'));
  }
  // if not, get them from the server and save them in session storage
  else {
    const posts = await firstValueFrom(this.http.get<PostModel[]>(`${environment.apiUrl}/posts`, {headers}));
    sessionStorage.setItem('posts', JSON.stringify(posts)); 
    return posts;
  }

}




async getOnePost(slug: string): Promise<PostModel> {
  if (sessionStorage.getItem('posts')) {
    const posts = JSON.parse(sessionStorage.getItem('posts'));
    const post = posts.find((post: PostModel) => post.slug === slug);
    return post;
  }
  else {
    const posts = await firstValueFrom(await this.http.get<PostModel[]>(`${environment.apiUrl}/posts`, {headers}));
    sessionStorage.setItem('posts', JSON.stringify(posts));
    const post = posts.find((post: PostModel) => post.slug === slug);
    return post;
  }
}

// create a function that will add a post to the server
async addPost(post: PostModel): Promise<PostModel> {
  console.log(post);
  
  const result = await firstValueFrom(this.http.post<PostModel>(`${environment.apiUrl}/posts`, post));
  return result;
}

// get 3 latest posts
async getLatestPosts(): Promise<PostModel[]> {
  if (sessionStorage.getItem('posts')) {
    const posts = JSON.parse(sessionStorage.getItem('posts'));
    const latestPosts = posts.slice(0, 3);
    return latestPosts;
  }
  else {
    const posts = await firstValueFrom(this.http.get<PostModel[]>(`${environment.apiUrl}/posts`, {headers}));
    sessionStorage.setItem('posts', JSON.stringify(posts));
    const latestPosts = posts.slice(0, 3);
    return latestPosts;
  }

}


// delete a post by slug
async deletePost(slug: string): Promise<PostModel> {
  const result = await firstValueFrom(this.http.delete<PostModel>(`${environment.apiUrl}/posts/${slug}`));
  console.log(slug);
  console.log(result);
  return result;
}

  


}
