import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agency-landing-two',
  templateUrl: './agency-landing-two.component.html',
  styleUrls: ['./agency-landing-two.component.scss']
})
export class AgencyLandingTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
