import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-sass-landing-one',
    templateUrl: './sass-landing-one.component.html',
    styleUrls: ['./sass-landing-one.component.scss']
})
export class SassLandingOneComponent implements OnInit {

    constructor(private titleService: Title, private metaService: Meta) {
        this.titleService.setTitle("אינטרלנד - בניית אתרים");
     }

    ngOnInit(): void {
        this.metaService.updateTag({ name: 'keywords', content: 'בניית אתרים מקצועיים בהתאמה אישית, אתרי תדמית, אתרי סחר (איקומרס), אתרי תוכן ועוד... עבודה מקצועית ומהירה, תמיכה ושירות מהלב לאורך כל הדרך' });
        this.metaService.updateTag({ name: 'robots', content: 'index, follow' });
        this.metaService.updateTag({ name: 'googlebot', content: 'index, follow' });
        this.metaService.updateTag({ name: 'author', content: 'טל נסימי' });
        this.metaService.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
        this.metaService.updateTag({ name: 'og:title', content: 'בניית אתרים' });
        this.metaService.updateTag({ name: 'og:description', content: 'בניית אתרים מקצועיים בהתאמה אישית, אתרי תדמית, אתרי סחר (איקומרס), אתרי תוכן ועוד... עבודה מקצועית ומהירה, תמיכה ושירות מהלב לאורך כל הדרך' });
        this.metaService.updateTag({ name: 'og:image', content: 'https://interland.co.il/assets/img/favicon/android-chrome-192x192.png' });
    }

}
