import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ContactModel } from 'src/app/models/contact-model';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  contactForm: FormGroup;
  contact: ContactModel;
  successMessage: string;
  errorMessage: string;
  successM : boolean = false;
  errorM : boolean = false;
  NgxSmartModalService: any;
  constructor(private emailService: EmailService) { }

  ngOnInit() {
    this.contactForm = new FormGroup({
      phoneNumber: new FormControl(''),
      subject: new FormControl(''),
      message: new FormControl(''),
      email: new FormControl(''),
      name: new FormControl(''),
      
    });
  }

  onSubmit() {
    // validate form
    if (!this.validateForm()) {
      return;
    }
    this.successMessage = 'ההודעה נשלחה בהצלחה';
    this.contact = this.contactForm.value;
    this.emailService.sendEmail(this.contact).subscribe(res => {
      this.successM = true;
      this.contactForm.reset();
    },
      err => {
        this.errorMessage = 'אירעה שגיאה בשליחת ההודעה';
        this.errorM = true;
    });
  }

  validateForm() {
    return this.contactForm.valid;
  }

}
